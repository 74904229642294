<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>基本资料</el-breadcrumb-item>
      <el-breadcrumb-item>客户管理</el-breadcrumb-item>
      <el-breadcrumb-item>添加客户管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="elcard">
        <el-form :model="addcustomerForm" :rules="addcustomerFormRules"
                 ref="addcustomerFormRef" label-width="130px"  class="demo-dynamic">
          <el-form-item label="客户名称：" prop="c_name">
            <el-input v-model="addcustomerForm.c_name"></el-input>
          </el-form-item>

          <el-form-item label="客户级别：" prop="addclidValue">
            <el-select v-model="addcustomerForm.addclidValue" placeholder="请选择"
                       @change="addNameSelectValue" clearable>
              <el-option
                  v-for="item in customerlevelList"
                  :key="item.cl_id"
                  :label="item.cl_name"
                  :value="item.cl_id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="业务员：" prop="addcsalesmanvalue">
            <el-select v-model="addcustomerForm.addcsalesmanvalue" placeholder="请选择"
                       @change="addSalesmanValue">
              <el-option
                  v-for="item in memberList"
                  :key="item.m_id"
                  :label="item.m_name"
                  :value="item.m_id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择地址" prop="selectListarea">
            <el-cascader
                v-model="addcustomerForm.selectListarea" clearable
                @change="addWareHouseChanged"
                :options="listarea" :props="cascaderProps"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="详细地址：" prop="c_address">
            <el-input v-model="addcustomerForm.c_address"></el-input>
          </el-form-item>
          <el-form-item label="备用信息：" prop="c_remark">
            <el-input v-model="addcustomerForm.c_remark"></el-input>
          </el-form-item>
<!--          <el-form-item label="结算方式：" prop="c_settlement">-->
<!--            <el-select v-model="addsettlementvalue" placeholder="请选择" @change="addSettlementValue">-->
<!--              <el-option-->
<!--                  v-for="item in settlementList"-->
<!--                  :key="item.id"-->
<!--                  :label="item.name"-->
<!--                  :value="item.id">-->
<!--              </el-option>-->
<!--            </el-select>-->
<!--          </el-form-item>-->
          <el-form-item label="结算方式：" prop="addsettlementvalue">
            <el-checkbox-group v-model="addcustomerForm.addsettlementvalue">
              <el-checkbox v-for="item in settlementList" @change="addSettlementValue"
                           :key="item.id"
                           :label="item.id">{{item.name}}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
<!--          <el-form-item label="发票抬头：" prop="c_invoice_title">-->
<!--            <el-input v-model="addcustomerForm.c_invoice_title"></el-input>-->
<!--          </el-form-item>-->
          <el-form-item label="纳税人识别号：" prop="c_taxpayer">
            <el-input v-model="addcustomerForm.c_taxpayer"></el-input>
          </el-form-item>
          <el-form-item label="营业执照地址：" prop="c_company_address">
            <el-input v-model="addcustomerForm.c_company_address"></el-input>
          </el-form-item>
          <el-form-item label="开户名称：" prop="c_account_name">
            <el-input v-model="addcustomerForm.c_account_name"></el-input>
          </el-form-item>
          <el-form-item label="开户银行：" prop="c_account_bank">
            <el-input v-model="addcustomerForm.c_account_bank"></el-input>
          </el-form-item>
          <el-form-item label="银行账号：" prop="c_bank_account">
            <el-input v-model="addcustomerForm.c_bank_account"></el-input>
          </el-form-item>

        <template v-for="(domain, index) in addcustomerForm.customercontacts">

          <el-form-item
                        :label="'姓名' + index" :key="domain.key"
                        :prop="'customercontacts.' + index + '.cc_name'"
                        :rules="{required: true, message: '姓名不能为空', trigger: 'blur'}">
            <el-input v-model="domain.cc_name"></el-input>
          </el-form-item>
          <el-form-item
                        :label="'电话' + index" :key="domain.key1"
                        :prop="'customercontacts.' + index + '.cc_phone'"
          >
            <el-input v-model="domain.cc_phone"></el-input>
          </el-form-item>
          <el-form-item
              :label="'职位' + index" :key="domain.key2"
              :prop="'customercontacts.' + index + '.cc_position'"
          >
            <el-input v-model="domain.cc_position"></el-input>
          </el-form-item>
          <el-form-item
              :label="'邮箱' + index" :key="domain.key3"
              :prop="'customercontacts.' + index + '.cc_email'"
          >
            <el-input v-model="domain.cc_email"></el-input>
          </el-form-item>
          <el-form-item  :key="domain.key3">
            <el-button @click.prevent="removeDomain(domain)" class="removinput">删除</el-button>
          </el-form-item>

        </template>

      </el-form>
      <div class="addContacts">
        <el-button @click="addDomain">新增联系人</el-button>
      </div>
      <div class="dialog-footer">
        <el-button @click="addgo">取 消</el-button>
        <el-button type="primary" @click="addcustomerlevel" :loading="addloading">确 定</el-button>
      </div>

    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return{
      addloading:false,
      // 添加客户信息数据
      addcustomerForm:{
        customercontacts: [{
          cc_name: '',
          cc_phone:'',
          cc_email:'',
          cc_position:'',
        }],
        addclidValue:'',
        addcsalesmanvalue:'',
        selectListarea:[],
        addsettlementvalue:[]
      },
      //客户等级
      customerlevelList:[],
      //业务员
      memberList:[],
      //客户级别 当前选中
      addclidValue:'',
      //业务员  当前选中
      addcsalesmanvalue:'',
      //结算方式 当前选中
      addsettlementvalue:[],
      //省市区的
      listarea:[],
      selectListarea:[],
      editselectListarea:[],
      cascaderProps: {
        expandTrigger: 'hover',
        // checkStrictly:true,
        value: 'la_id',
        label: 'la_name',
        children: 'children'
      },
      //结算方式
      settlementList:[
        {id:1,name:'预付'},
        {id:2,name:'现付'},
        {id:3,name:'后付'},
      ],
      addcustomerFormRules:{
        c_name:[
          {required: true, message: '请输入客户名称', trigger: 'blur'}
        ],
        // c_address:[
        //   {required: true, message: '请输入详细地址', trigger: 'blur'}
        // ],
        // c_remark:[
        //   {required: true, message: '请输入备用信息', trigger: 'blur'}
        // ],
        // c_invoice_title:[
        //   {required: true, message: '请输入发票抬头', trigger: 'blur'}
        // ],
        // c_taxpayer:[
        //   {required: true, message: '请输入纳税人识别号', trigger: 'blur'}
        // ],
        // c_company_address:[
        //   {required: true, message: '请输入地址', trigger: 'blur'}
        // ],
        // c_account_name:[
        //   {required: true, message: '请输入开户名称', trigger: 'blur'}
        // ],
        // c_account_bank:[
        //   {required: true, message: '请输入开户银行', trigger: 'blur'}
        // ],
        // c_bank_account:[
        //   {required: true, message: '请输入银行账号', trigger: 'blur'}
        // ],
        // addclidValue:[
        //   {required: true, message: '请选择客户级别', trigger: 'change'}
        // ],
        // addcsalesmanvalue:[
        //   {required: true, message: '请选择业务员', trigger: 'change'}
        // ],
        // selectListarea:[
        //   {required: true, message: '请选择地址', trigger: 'change'}
        // ],
        // addsettlementvalue:[
        //   {required: true, message: '请选择结算方式', trigger: 'change'}
        // ],

      }
    }
  },
  created() {
    this.getcustomerlevelList()
    this.getmemberList()
    this.getWarehouseArea()
  },
  methods: {
    //获取客户级别
    async getcustomerlevelList() {
      const {data: res} = await this.$http.get('customerlevel/getMainList')
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      // 把数据列表 赋值
      this.customerlevelList = res.data
    },
    //获取业务员
    async getmemberList() {
      const {data: res} = await this.$http.get('member/getMainList')
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      // 把数据列表 赋值
      this.memberList = res.data
    },
    //获取省市区
    async getWarehouseArea(){
      const {data:res}=await this.$http.get('listarea')
      if (res.code!=200) return this.$message.error('获取省市区数据失败')
      this.listarea = res.data
      console.log(this.listarea)
    },
    // 添加 监听级联选择器 选中节点变化时触发
    addWareHouseChanged(){
      // console.log(this.selectListarea)
      this.addcustomerForm.c_province=this.addcustomerForm.selectListarea[0]
      this.addcustomerForm.c_city=this.addcustomerForm.selectListarea[1]
      this.addcustomerForm.c_area=this.addcustomerForm.selectListarea[2]
    },
    //select name发生变化时触发
    addNameSelectValue(){
      this.addcustomerForm.cl_id=this.addcustomerForm.addclidValue
    },
    //select salesman发生变化时触发
    addSalesmanValue(){
      this.addcustomerForm.c_salesman=this.addcustomerForm.addcsalesmanvalue
    },
    //select settlement发生变化时触发
    addSettlementValue(){
      this.addcustomerForm.c_settlement=this.addcustomerForm.addsettlementvalue
    },
    //点击提交
    addcustomerlevel(){
      this.$refs.addcustomerFormRef.validate(async valid => {
        if (!valid) return
        this.addloading=true
        const {data: res} = await this.$http.post('customer/add',
            this.addcustomerForm)
        this.addloading=false
        if (res.code !== 200) return this.$message.error(res.msg)
        this.$message.success(res.msg)
        this.$router.go(-1)

      })
    },
    addDomain() {
      this.addcustomerForm.customercontacts.push({
        cc_name: '',
        cc_phone:'',
        cc_position:'',
        cc_email:'',
        key: Date.now(),
        key1: Date.now()+1,
        key2: Date.now()+2,
        key3: Date.now()+3,
      });
    },
    removeDomain(item) {
      var index = this.addcustomerForm.customercontacts.indexOf(item)
      if (index !== -1) {
        this.addcustomerForm.customercontacts.splice(index, 1)
      }
    },
    addgo(){
      this.$router.go(-1)
    }

  }
}
</script>
<style lang="less" scoped>
.elcard{
  height: 80vh;
  overflow: auto;
}
.addContacts{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}
.dialog-footer{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
::-webkit-scrollbar {
  width: 10px; /*对垂直流动条有效*/
}
/*定义滚动条的轨道颜色、内阴影及圆角*/
::-webkit-scrollbar-track{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #ffffff;
  border-radius: 3px;
}
/*定义滑块颜色、内阴影及圆角*/
::-webkit-scrollbar-thumb{
  border-radius: 7px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #E8E8E8;
}
</style>
